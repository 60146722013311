import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { db } from "./firebase.js";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import Header from "./Components/Header.js";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Css/VerMir.css";
import { ArrowBack } from "@mui/icons-material";
import logoIzquierda from "./Images/LogoAyuntamiento.png";
import logoDerecha from "./Images/LogoImplanNuevo.jpeg";
import {
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";

function VerMIR() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { dependenciaPrograma, docPath } = location.state || {};
  const [nombrePrograma, setNombrePrograma] = useState("");
  const { mirId } = useParams();
  const [mirData, setMirData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
    } else {
      navigate("/login");
    }
    const fetchMirData = async () => {
      if (docPath && mirId) {
        const patDocRef = doc(db, `${docPath}/Mir`, mirId);
        const patDocSnap = await getDoc(patDocRef);
        if (patDocSnap.exists()) {
          setMirData({ id: patDocSnap.id, ...patDocSnap.data() });
        }
      }
    };

    const fetchProgramaMIR = async () => {
      const mirDocRef = doc(db, `${docPath}/Mir`, mirId);
      const docSnap = await getDoc(mirDocRef);
      if (docSnap.exists()) {
        setNombrePrograma(docSnap.data().nombrePrograma);
      } else {
        console.log("No such document!");
      }
    };

    fetchMirData();
    fetchProgramaMIR();
  }, [docPath, mirId, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("userName");
    navigate("/login");
  };

  const handleBackClick = () => {
    navigate("/directorymir");
  };

  const generatePDF = async () => {
    const pdf = new jsPDF("landscape", "mm", "a4");
    const content = document.getElementById("content");

    if (!content) {
      console.error("No se encontró el contenedor.");
      return;
    }

    // Captura el contenido como imagen
    const canvas = await html2canvas(content, { scale: 1.5 });
    const imgData = canvas.toDataURL("image/jpeg", 0.9);

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    const imgWidth = pageWidth;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    let yPosition = 0;

    while (yPosition < imgHeight) {
      pdf.addImage(imgData, "PNG", 0, -yPosition, imgWidth, imgHeight);

      yPosition += pageHeight;

      if (yPosition < imgHeight) {
        pdf.addPage();
      }
    }

    pdf.save(`MIR ${nombrePrograma.toUpperCase()}.pdf`);
  };

  const deleteMIR = async () => {
    if (name !== mirData.userName) {
      setSnackbarMessage(
        "ESTE USUARIO NO PUEDE ELIMINAR ESTA MATRIZ DE INDICADORES DE RESULTADOS"
      );
      setSnackbarOpen(true);
      return;
    }
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (docPath && mirId) {
      const patDocRef = doc(db, `${docPath}/Mir`, mirId);
      await deleteDoc(patDocRef);
      setSuccessSnackbarOpen(true);
      setTimeout(() => {
        navigate("/directorymir");
      }, 4000);
    }
    setConfirmDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleSuccessSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };

  return (
    <div id="content" className="ver-mir-container">
      <Header name={name} handleLogout={handleLogout} />
      <img
        src={logoIzquierda}
        alt="Logo Izquierdo"
        className="logo-izquierda"
        data-aos="fade-up"
      />
      <img
        src={logoDerecha}
        alt="Logo Derecho"
        className="logo-derecha"
        data-aos="fade-up"
      />
      <div className="ver-mir-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="zoom-in">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="formulario-mir-title" data-aos="zoom-in">
        <h1>XLII AYUNTAMIENTO DE XALISCO</h1>
        <h1>MATRIZ DE INDICADORES DE RESULTADOS</h1>
        <h3>PROGRAMA: {mirData.nombrePrograma?.toUpperCase()}</h3>
        <h3>UNIDAD RESPONSABLE: {dependenciaPrograma.toUpperCase()}</h3>
      </div>
      <div
        className="ver-mir-content"
        id="pdf-content"
        data-aos="fade-left"
        delay="1000"
      >
        {mirData ? (
          <div className="mir-grid">
            <Button
              variant="contained"
              color="primary"
              onClick={generatePDF}
              data-aos="zoom-in"
            >
              GENERAR MIR EN PDF
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={deleteMIR}
              data-aos="zoom-in"
            >
              ELIMINAR MIR
            </Button>

            <div className="mir-category">
              <h3>Datos del programa</h3>
              <div className="mir-table">
                <div className="mir-table-header">
                  <div className="mir-grid-header">Programa presupuestario</div>
                  <div className="mir-grid-header">Objetivo</div>
                  <div className="mir-grid-header">Dependencia general</div>
                  <div className="mir-grid-header">
                    Eje Rector o Eje Transversal
                  </div>
                  <div className="mir-grid-header">Tema de desarrollo</div>
                </div>
                <div className="mir-table-row">
                  <div className="mir-grid-item">{mirData.nombrePrograma}</div>
                  <div className="mir-grid-item">
                    {mirData.objetivoPrograma}
                  </div>
                  <div className="mir-grid-item">
                    {mirData.dependenciaPrograma}
                  </div>
                  <div className="mir-grid-item">{mirData.ejeRector}</div>
                  <div className="mir-grid-item">{mirData.temaDesarrollo}</div>
                </div>
              </div>
            </div>

            <div className="mir-category">
              <h3>Renglón Fin</h3>
              {Array.isArray(mirData?.fin) &&
                mirData.fin.map((fin) => (
                  <>
                    <div className="mir-table-renglones">
                      <div className="mir-table-header">
                        <div className="mir-grid-header">
                          Objetivo o resumen narrativo
                        </div>
                        <div className="mir-grid-header">Nombre</div>
                        <div className="mir-grid-header">Fórmula</div>
                        <div className="mir-grid-header">Frecuencia y Tipo</div>
                        <div className="mir-grid-header">
                          Medios de verificación
                        </div>
                        <div className="mir-grid-header">Supuestos</div>
                      </div>
                      <div className="mir-table-row">
                        <div className="mir-grid-item">
                          {fin.objetivoRenglónFin}
                        </div>
                        <div className="mir-grid-item">
                          {fin.nombreRenglónFin}
                        </div>
                        <div className="mir-grid-item">
                          {fin.fórmulaRenglónFin}
                        </div>
                        <div className="mir-grid-item">
                          {fin.frecuenciaTipoRenglónFin}
                        </div>
                        <div className="mir-grid-item">
                          {fin.mediosVerificaciónRenglónFin}
                        </div>
                        <div className="mir-grid-item">
                          {fin.supuestosRenglónFin}
                        </div>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>U. De medida / Frecuencia</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Unidad de medida:{" "}
                                {fin.unidadMedidaRenglónFin.unidadMedidaFin}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Frecuencia de medición:{" "}
                                {fin.unidadMedidaRenglónFin.frecuenciaFin}
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Tipo / Dimensión</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Tipo: {fin.tipoRenglónFin.tipoFin}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Dimensión: {fin.tipoRenglónFin.dimensiónFin}
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Sentido</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Sentido: {fin.sentidoRenglónFin}</strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Línea base</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Año: {fin.lineaBaseRenglónFin.añoLineaBaseFin}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Línea base:{" "}
                                {fin.lineaBaseRenglónFin.lineaBaseFin}
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Variables de la fórmula</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Variable 1:{" "}
                                {fin.variablesFórmulaRenglónFin.variableFin1}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Variable 2:{" "}
                                {fin.variablesFórmulaRenglónFin.variableFin2}
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Metas</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {fin.metasRenglónFin.datosVariablesFin1}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {fin.metasRenglónFin.datosVariablesFin2}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado: {fin.metasRenglónFin.resultadoFin}
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>1er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {fin.primerTrimestreRenglónFin.datosFin1}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {fin.primerTrimestreRenglónFin.datoFin2}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado:{" "}
                                {fin.primerTrimestreRenglónFin.resultadoFin}
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>2do trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {fin.segundoTrimestreRenglónFin.datosFin1}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {fin.segundoTrimestreRenglónFin.datosFin2}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado:{" "}
                                {fin.segundoTrimestreRenglónFin.resultadoFin}
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>3er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {fin.tercerTrimestreRenglónFin.datosFin1}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {fin.tercerTrimestreRenglónFin.datosFin2}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado:{" "}
                                {fin.tercerTrimestreRenglónFin.resultadoFin}
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>4to trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {fin.cuartoTrimestreRenglónFin.datosFin1}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {fin.cuartoTrimestreRenglónFin.datosFin2}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado:{" "}
                                {fin.cuartoTrimestreRenglónFin.resultadoFin}
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Justificación de resultados</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Análisis:{" "}
                                {
                                  fin.justificaciónResultadosRenglónFin
                                    .análisisFin
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Medios de verificación:{" "}
                                {
                                  fin.justificaciónResultadosRenglónFin
                                    .mediosVerificaciónFin
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Notas complementarias</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Notas: {fin.notasComplementariasFin}
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </>
                ))}
            </div>

            <div className="mir-category">
              <h3>Renglón Propósito</h3>
              {Array.isArray(mirData?.propósito) &&
                mirData.propósito.map((propósito) => (
                  <>
                    <div className="mir-table-renglones">
                      <div className="mir-table-header">
                        <div className="mir-grid-header">
                          Objetivo o resumen narrativo
                        </div>
                        <div className="mir-grid-header">Nombre</div>
                        <div className="mir-grid-header">Fórmula</div>
                        <div className="mir-grid-header">Frecuencia y Tipo</div>
                        <div className="mir-grid-header">
                          Medios de verificación
                        </div>
                        <div className="mir-grid-header">Supuestos</div>
                      </div>
                      <div className="mir-table-row">
                        <div className="mir-grid-item">
                          {propósito.objetivoRenglónPropósito}
                        </div>
                        <div className="mir-grid-item">
                          {propósito.nombreRenglónPropósito}
                        </div>
                        <div className="mir-grid-item">
                          {propósito.fórmulaRenglónPropósito}
                        </div>
                        <div className="mir-grid-item">
                          {propósito.frecuenciaTipoRenglónPropósito}
                        </div>
                        <div className="mir-grid-item">
                          {propósito.mediosVerificaciónRenglónPropósito}
                        </div>
                        <div className="mir-grid-item">
                          {propósito.supuestosRenglónPropósito}
                        </div>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>U. De medida / Frecuencia</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Unidad de medida:{" "}
                                {
                                  propósito.unidadMedidaRenglónPropósito
                                    .unidadMedidaPropósito
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Frecuencia de medición:{" "}
                                {
                                  propósito.unidadMedidaRenglónPropósito
                                    .frecuenciaPropósito
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Tipo / Dimensión</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Tipo:{" "}
                                {propósito.tipoRenglónPropósito.tipoPropósito}
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Dimensión:{" "}
                                {
                                  propósito.tipoRenglónPropósito
                                    .dimensiónPropósito
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Sentido</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Sentido: {propósito.sentidoRenglónPropósito}
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Línea base</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Año:{" "}
                                {
                                  propósito.lineaBaseRenglónPropósito
                                    .añoLineaBasePropósito
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Línea base:{" "}
                                {
                                  propósito.lineaBaseRenglónPropósito
                                    .lineaBasePropósito
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Variables de la fórmula</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Variable 1:{" "}
                                {
                                  propósito.variablesFórmulaRenglónPropósito
                                    .variablePropósito1
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Variable 2:{" "}
                                {
                                  propósito.variablesFórmulaRenglónPropósito
                                    .variablePropósito2
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Metas</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {
                                  propósito.metasRenglónPropósito
                                    .datosVariablesPropósito1
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {
                                  propósito.metasRenglónPropósito
                                    .datosVariablesPropósito2
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado:{" "}
                                {
                                  propósito.metasRenglónPropósito
                                    .resultadoPropósito
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>1er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {
                                  propósito.primerTrimestreRenglónPropósito
                                    .datosPropósito1
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {
                                  propósito.primerTrimestreRenglónPropósito
                                    .datoPropósito2
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado:{" "}
                                {
                                  propósito.primerTrimestreRenglónPropósito
                                    .resultadoPropósito
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>2do trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {
                                  propósito.segundoTrimestreRenglónPropósito
                                    .datosPropósito1
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {
                                  propósito.segundoTrimestreRenglónPropósito
                                    .datosPropósito2
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado:{" "}
                                {
                                  propósito.segundoTrimestreRenglónPropósito
                                    .resultadoPropósito
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>3er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {
                                  propósito.tercerTrimestreRenglónPropósito
                                    .datosPropósito1
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {
                                  propósito.tercerTrimestreRenglónPropósito
                                    .datosPropósito2
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado:{" "}
                                {
                                  propósito.tercerTrimestreRenglónPropósito
                                    .resultadoPropósito
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>4to trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {
                                  propósito.cuartoTrimestreRenglónPropósito
                                    .datosPropósito1
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {
                                  propósito.cuartoTrimestreRenglónPropósito
                                    .datosPropósito2
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado:{" "}
                                {
                                  propósito.cuartoTrimestreRenglónPropósito
                                    .resultadoPropósito
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Justificación de resultados</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Análisis:{" "}
                                {
                                  propósito
                                    .justificaciónResultadosRenglónPropósito
                                    .análisisPropósito
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Medios de verificación:{" "}
                                {
                                  propósito
                                    .justificaciónResultadosRenglónPropósito
                                    .mediosVerificaciónPropósito
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Notas complementarias</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Notas: {propósito.notasComplementariasPropósito}
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </>
                ))}
            </div>

            <div className="mir-category">
              <h3>Renglón Componente</h3>
              {Array.isArray(mirData?.componente) &&
                mirData.componente.map((componente, index) => (
                  <>
                    <Typography>Componente No. {index + 1}</Typography>
                    <div className="mir-table-renglones">
                      <div className="mir-table-header">
                        <div className="mir-grid-header">
                          Objetivo o resumen narrativo
                        </div>
                        <div className="mir-grid-header">Nombre</div>
                        <div className="mir-grid-header">Fórmula</div>
                        <div className="mir-grid-header">Frecuencia y Tipo</div>
                        <div className="mir-grid-header">
                          Medios de verificación
                        </div>
                        <div className="mir-grid-header">Supuestos</div>
                      </div>
                      <div className="mir-table-row">
                        <div className="mir-grid-item">
                          {componente.objetivoRenglónComponente}
                        </div>
                        <div className="mir-grid-item">
                          {componente.nombreRenglónComponente}
                        </div>
                        <div className="mir-grid-item">
                          {componente.fórmulaRenglónComponente}
                        </div>
                        <div className="mir-grid-item">
                          {componente.frecuenciaTipoRenglónComponente}
                        </div>
                        <div className="mir-grid-item">
                          {componente.mediosVerificaciónRenglónComponente}
                        </div>
                        <div className="mir-grid-item">
                          {componente.supuestosRenglónComponente}
                        </div>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>U. De medida / Frecuencia</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Unidad de medida:{" "}
                                {
                                  componente.unidadMedidaRenglónComponente
                                    .unidadMedidaComponente
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Frecuencia de medición:{" "}
                                {
                                  componente.unidadMedidaRenglónComponente
                                    .frecuenciaComponente
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Tipo / Dimensión</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Tipo:{" "}
                                {
                                  componente.tipoRenglónComponente
                                    .tipoComponente
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Dimensión:{" "}
                                {
                                  componente.tipoRenglónComponente
                                    .dimensiónComponente
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Sentido</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Sentido: {componente.sentidoRenglónComponente}
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Línea base</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Año:{" "}
                                {
                                  componente.lineaBaseRenglónComponente
                                    .añoLineaBaseComponente
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Línea base:{" "}
                                {
                                  componente.lineaBaseRenglónComponente
                                    .lineaBaseComponente
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Variables de la fórmula</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Variable 1:{" "}
                                {
                                  componente.variablesFórmulaRenglónComponente
                                    .variableComponente1
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Variable 2:{" "}
                                {
                                  componente.variablesFórmulaRenglónComponente
                                    .variableComponente2
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Metas</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {
                                  componente.metasRenglónComponente
                                    .datosVariablesComponente1
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {
                                  componente.metasRenglónComponente
                                    .datosVariablesComponente2
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado:{" "}
                                {
                                  componente.metasRenglónComponente
                                    .resultadoComponente
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>1er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {
                                  componente.primerTrimestreRenglónComponente
                                    .datosComponente1
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {
                                  componente.primerTrimestreRenglónComponente
                                    .datosComponente2
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado:{" "}
                                {
                                  componente.primerTrimestreRenglónComponente
                                    .resultadoComponente
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>2do trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {
                                  componente.segundoTrimestreRenglónComponente
                                    .datosComponente1
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {
                                  componente.segundoTrimestreRenglónComponente
                                    .datosComponente2
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado:{" "}
                                {
                                  componente.segundoTrimestreRenglónComponente
                                    .resultadoComponente
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>3er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {
                                  componente.tercerTrimestreRenglónComponente
                                    .datosComponente1
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {
                                  componente.tercerTrimestreRenglónComponente
                                    .datosComponente2
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado:{" "}
                                {
                                  componente.tercerTrimestreRenglónComponente
                                    .resultadoComponente
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>4to trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 1:{" "}
                                {
                                  componente.cuartoTrimestreRenglónComponente
                                    .datosComponente1
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Dato de las variables 2:{" "}
                                {
                                  componente.cuartoTrimestreRenglónComponente
                                    .datosComponente2
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                {" "}
                                Resultado:{" "}
                                {
                                  componente.cuartoTrimestreRenglónComponente
                                    .resultadoComponente
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Justificación de resultados</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Análisis:{" "}
                                {
                                  componente
                                    .justificaciónResultadosRenglónComponente
                                    .análisisComponente
                                }
                              </strong>
                            </div>
                            <div className="mir-grid-item">
                              <strong>
                                Medios de verificación:{" "}
                                {
                                  componente
                                    .justificaciónResultadosRenglónComponente
                                    .mediosVerificaciónComponente
                                }
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Notas complementarias</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong>
                                Notas:{" "}
                                {componente.notasComplementariasComponente}
                              </strong>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </>
                ))}
            </div>

            <div className="mir-category">
              <h3>Responsable y Titular de la dependencia</h3>
              <div className="mir-table-responsable">
                <div className="mir-table-header">
                  <div className="mir-grid-header">
                    Responsable de la elaboración
                  </div>
                  <div className="mir-grid-header">
                    Titular de la dependencia
                  </div>
                </div>
                <div className="mir-table-row">
                  <div className="mir-grid-item">{mirData.userName}</div>
                  <div className="mir-grid-item">
                    {mirData.titularDependencia}
                  </div>
                </div>
              </div>
            </div>

            <div className="mir-category-actividades">
              <h3>Actividades</h3>
              {Array.isArray(mirData?.actividades) &&
                mirData.actividades.map((actividad, index) => (
                  <Accordion
                    key={index}
                    className="avance-mir-accordion"
                    expanded
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className="mir-accordion-summary"
                    >
                      <Typography>Actividad No. {index + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="mir-accordion-details">
                      <div className="actividad">
                        <div className="mir-grid-item">
                          <strong> Nombre: </strong>
                          {actividad.nombreActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Objetivo: </strong>
                          {actividad.objetivoActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Fórmula: </strong>{" "}
                          {actividad.fórmulaActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Frecuencia y Tipo: </strong>{" "}
                          {actividad.frecuenciaTipoActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Medios de verificación: </strong>{" "}
                          {actividad.mediosVerificaciónActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Supuestos: </strong>{" "}
                          {actividad.supuestosActividad || "N/A"}
                        </div>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>U. De medida / Frecuencia</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Unidad de medida: </strong>
                              {actividad.unidadMedidaActividad.unidadMedida}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Frecuencia de medición: </strong>
                              {actividad.unidadMedidaActividad.frecuencia}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Tipo / Dimensión</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Tipo: </strong>
                              {actividad.tipoActividad.tipo}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dimensión: </strong>
                              {actividad.tipoActividad.dimensión}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <div className="mir-grid-item">
                          <strong> Sentido: </strong>
                          {actividad.sentidoActividad}
                        </div>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Linea base</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Año: </strong>
                              {actividad.lineaBaseActividad.añoLineaBase}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Línea base: </strong>
                              {actividad.lineaBaseActividad.lineaBase}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Variables de la fórmula</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Variable 1: </strong>
                              {
                                actividad.variablesFórmulaActividades
                                  .variableActividad1
                              }
                            </div>
                            <div className="mir-grid-item">
                              <strong> Variable 2: </strong>
                              {
                                actividad.variablesFórmulaActividades
                                  .variableActividad2
                              }
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Metas</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              {actividad.metasActividades.datosVariables1}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              {actividad.metasActividades.datosVariables2}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              {actividad.metasActividades.resultado}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>1er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              {actividad.primerTrimestre.datos1}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              {actividad.primerTrimestre.datos2}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              {actividad.primerTrimestre.resultado}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>2do trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              {actividad.segundoTrimestre.datos1}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              {actividad.segundoTrimestre.datos2}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              {actividad.segundoTrimestre.resultado}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>3er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              {actividad.tercerTrimestre.datos1}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              {actividad.tercerTrimestre.datos2}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              {actividad.tercerTrimestre.resultado}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>4to trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              {actividad.cuartoTrimestre.datos1}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              {actividad.cuartoTrimestre.datos2}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              {actividad.cuartoTrimestre.resultado}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Justificación de resultados</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Análisis: </strong>
                              {actividad.justificaciónResultados.análisis}
                            </div>
                            <div className="mir-grid-item">
                              <strong> Medios de verificación: </strong>
                              {
                                actividad.justificaciónResultados
                                  .mediosVerificación
                              }
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <div className="mir-grid-item">
                          <strong> Notas complementarias: </strong>
                          {actividad.notasComplementarias}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          </div>
        ) : (
          <p>
            Aún no se ha agregado ninguna matriz de indicadores de resultados
          </p>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleSuccessSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Matriz de indicadores de resultados eliminada correctamente
        </Alert>
      </Snackbar>
      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro que desea eliminar esta matriz de indicadores de
            resultados?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default VerMIR;
