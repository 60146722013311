import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { db } from "./firebase.js";
import { doc, getDoc, setDoc } from "firebase/firestore";
import jsPDF from "jspdf";
import Header from "./Components/Header.js";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Css/AvanceMIR.css";
import { ArrowBack } from "@mui/icons-material";
import logoIzquierda from "./Images/LogoAyuntamiento.png";
import logoDerecha from "./Images/LogoImplanNuevo.jpeg";
import {
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditableField from "./Components/EditableField";

function AvanceMIR() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { dependenciaPrograma, docPath } = location.state || {};
  const { mirId } = useParams();
  const [mirData, setMirData] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [nombreMIR, setNombreMIR] = useState("");
  const [nombrePrograma, setNombrePrograma] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
    } else {
      navigate("/login");
    }

    const fetchMirData = async () => {
      if (docPath && mirId) {
        const mirDocRef = doc(db, `${docPath}/Mir`, mirId);
        const mirDocSnap = await getDoc(mirDocRef);
        if (mirDocSnap.exists()) {
          const data = { id: mirDocSnap.id, ...mirDocSnap.data() };
          setMirData(data);
        }
      }
    };

    const fetchNombreMIR = async () => {
      const mirDocRef = doc(db, `${docPath}/Mir`, mirId);
      const docSnap = await getDoc(mirDocRef);
      if (docSnap.exists()) {
        setNombreMIR(docSnap.data().nombreMIR);
      } else {
        console.log("No such document!");
      }
    };

    const fetchProgramaMIR = async () => {
      const mirDocRef = doc(db, `${docPath}/Mir`, mirId);
      const docSnap = await getDoc(mirDocRef);
      if (docSnap.exists()) {
        setNombrePrograma(docSnap.data().nombrePrograma);
      } else {
        console.log("No such document!");
      }
    };

    const checkEditableDates = async () => {
      const docRef = doc(db, "admin", "editDates");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const { startDate, endDate } = docSnap.data();
        const currentDate = new Date().toISOString().split("T")[0];
        if (currentDate >= startDate && currentDate <= endDate) {
          setIsEditable(true);
        }
      }
    };

    fetchMirData();
    checkEditableDates();
    fetchNombreMIR();
    fetchProgramaMIR();
  }, [docPath, mirId, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("userName");
    navigate("/login");
  };

  const handleBackClick = () => {
    navigate("/directorymir");
  };

  const handleSave = (index, field, newValue, section) => {
    const updatedMirData = { ...mirData };
    let currentSection;

    if (section === "actividades") {
      currentSection = updatedMirData.actividades[index];
    } else if (section === "fin") {
      currentSection = updatedMirData.fin[index];
    } else if (section === "propósito") {
      currentSection = updatedMirData.propósito[index];
    } else if (section === "componente") {
      currentSection = updatedMirData.componente[index];
    } else {
      console.error("Sección no válida");
      return;
    }

    const fieldMap = {
      // Campos de "actividades"
      "unidadMedida": "unidadMedidaActividad.unidadMedida",
      "frecuencia": "unidadMedidaActividad.frecuencia",

      "tipo": "tipoActividad.tipo",
      "dimensión": "tipoActividad.dimensión",

      "sentidoActividad": "sentidoActividad",

      "añoLineaBase": "lineaBaseActividad.añoLineaBase",
      "lineaBase": "lineaBaseActividad.lineaBase",

      "variableActividad1": "variablesFórmulaActividades.variableActividad1",
      "variableActividad2": "variablesFórmulaActividades.variableActividad2",

      "datosVariables1": "metasActividades.datosVariables1",
      "datosVariables2": "metasActividades.datosVariables2",
      "resultado": "metasActividades.resultado",

      "primerTrimestre.datos1": "primerTrimestre.datos1",
      "primerTrimestre.datos2": "primerTrimestre.datos2",
      "primerTrimestre.resultado": "primerTrimestre.resultado",

      "segundoTrimestre.datos1": "segundoTrimestre.datos1",
      "segundoTrimestre.datos2": "segundoTrimestre.datos2",
      "segundoTrimestre.resultado": "segundoTrimestre.resultado",

      "tercerTrimestre.datos1": "tercerTrimestre.datos1",
      "tercerTrimestre.datos2": "tercerTrimestre.datos2",
      "tercerTrimestre.resultado": "tercerTrimestre.resultado",

      "cuartoTrimestre.datos1": "cuartoTrimestre.datos1",
      "cuartoTrimestre.datos2": "cuartoTrimestre.datos2",
      "cuartoTrimestre.resultado": "cuartoTrimestre.resultado",

      "justificaciónResultados.análisis": "justificaciónResultados.análisis",
      "justificaciónResultados.mediosVerificación": "justificaciónResultados.mediosVerificación",

      "notasComplementarias": "notasComplementarias",

      // Campos de "fin"
      "unidadMedidaFin": "unidadMedidaRenglónFin.unidadMedidaFin",
      "frecuenciaFin": "unidadMedidaRenglónFin.frecuenciaFin",

      "tipoFin": "tipoRenglónFin.tipoFin",
      "dimensiónFin": "tipoRenglónFin.dimensiónFin",

      "sentidoRenglónFin": "sentidoRenglónFin",

      "añoLineaBaseFin": "lineaBaseRenglónFin.añoLineaBaseFin",
      "lineaBaseFin": "lineaBaseRenglónFin.lineaBaseFin",

      "variableFin1": "variablesFórmulaRenglónFin.variableFin1",
      "variableFin2": "variablesFórmulaRenglónFin.variableFin2",

      "datosVariablesFin1": "metasRenglónFin.datosVariablesFin1",
      "datosVariablesFin2": "metasRenglónFin.datosVariablesFin2",
      "resultadoFin": "metasRenglónFin.resultadoFin",

      "primerTrimestreRenglónFin.datosFin1": "primerTrimestreRenglónFin.datosFin1",
      "primerTrimestreRenglónFin.datosFin2": "primerTrimestreRenglónFin.datosFin2",
      "primerTrimestreRenglónFin.resultadoFin": "primerTrimestreRenglónFin.resultadoFin",

      "segundoTrimestreRenglónFin.datosFin1": "segundoTrimestreRenglónFin.datosFin1",
      "segundoTrimestreRenglónFin.datosFin2": "segundoTrimestreRenglónFin.datosFin2",
      "segundoTrimestreRenglónFin.resultadoFin": "segundoTrimestreRenglónFin.resultadoFin",

      "tercerTrimestreRenglónFin.datosFin1": "tercerTrimestreRenglónFin.datosFin1",
      "tercerTrimestreRenglónFin.datosFin2": "tercerTrimestreRenglónFin.datosFin2",
      "tercerTrimestreRenglónFin.resultadoFin": "tercerTrimestreRenglónFin.resultadoFin",

      "cuartoTrimestreRenglónFin.datosFin1": "cuartoTrimestreRenglónFin.datosFin1",
      "cuartoTrimestreRenglónFin.datosFin2": "cuartoTrimestreRenglónFin.datosFin2",
      "cuartoTrimestreRenglónFin.resultadoFin": "cuartoTrimestreRenglónFin.resultadoFin",

      "justificaciónResultadosRenglónFin.análisisFin": "justificaciónResultadosRenglónFin.análisisFin",
      "justificaciónResultadosRenglónFin.mediosVerificaciónFin": "justificaciónResultadosRenglónFin.mediosVerificaciónFin",

      "notasComplementariasFin": "notasComplementariasFin",
      
      // Campos de "propósito"
      "unidadMedidaPropósito": "unidadMedidaRenglónPropósito.unidadMedidaPropósito",
      "frecuenciaPropósito": "unidadMedidaRenglónPropósito.frecuenciaPropósito",

      "tipoPropósito": "tipoRenglónPropósito.tipoPropósito",
      "dimensiónPropósito": "tipoRenglónPropósito.dimensiónPropósito",

      "sentidoRenglónPropósito": "sentidoRenglónPropósito",

      "añoLineaBasePropósito": "lineaBaseRenglónPropósito.añoLineaBasePropósito",
      "lineaBasePropósito": "lineaBaseRenglónPropósito.lineaBasePropósito",

      "variablePropósito1": "variablesFórmulaRenglónPropósito.variablePropósito1",
      "variablePropósito2": "variablesFórmulaRenglónPropósito.variablePropósito2",

      "datosVariablesPropósito1": "metasRenglónPropósito.datosVariablesPropósito1",
      "datosVariablesPropósito2": "metasRenglónPropósito.datosVariablesPropósito2",
      "resultadoPropósito": "metasRenglónPropósito.resultadoPropósito",

      "primerTrimestreRenglónPropósito.datosPropósito1": "primerTrimestreRenglónPropósito.datosPropósito1",
      "primerTrimestreRenglónPropósito.datosPropósito2": "primerTrimestreRenglónPropósito.datosPropósito2",
      "primerTrimestreRenglónPropósito.resultadoPropósito": "primerTrimestreRenglónPropósito.resultadoPropósito",

      "segundoTrimestreRenglónPropósito.datosPropósito1": "segundoTrimestreRenglónPropósito.datosPropósito1",
      "segundoTrimestreRenglónPropósito.datosPropósito2": "segundoTrimestreRenglónPropósito.datosPropósito2",
      "segundoTrimestreRenglónPropósito.resultadoPropósito": "segundoTrimestreRenglónPropósito.resultadoPropósito",

      "tercerTrimestreRenglónPropósito.datosPropósito1": "tercerTrimestreRenglónPropósito.datosPropósito1",
      "tercerTrimestreRenglónPropósito.datosPropósito2": "tercerTrimestreRenglónPropósito.datosPropósito2",
      "tercerTrimestreRenglónPropósito.resultadoPropósito": "tercerTrimestreRenglónPropósito.resultadoPropósito",

      "cuartoTrimestreRenglónPropósito.datosPropósito1": "cuartoTrimestreRenglónPropósito.datosPropósito1",
      "cuartoTrimestreRenglónPropósito.datosPropósito2": "cuartoTrimestreRenglónPropósito.datosPropósito2",
      "cuartoTrimestreRenglónPropósito.resultadoPropósito": "cuartoTrimestreRenglónPropósito.resultadoPropósito",

      "justificaciónResultadosRenglónPropósito.análisisPropósito": "justificaciónResultadosRenglónPropósito.análisisPropósito",
      "justificaciónResultadosRenglónPropósito.mediosVerificaciónPropósito": "justificaciónResultadosRenglónPropósito.mediosVerificaciónPropósito",

      "notasComplementariasPropósito": "notasComplementariasPropósito",

      // Campos de "componente"
      "unidadMedidaComponente": "unidadMedidaRenglónComponente.unidadMedidaComponente",
      "frecuenciaComponente": "unidadMedidaRenglónComponente.frecuenciaComponente",

      "tipoComponente": "tipoRenglónComponente.tipoComponente",
      "dimensiónComponente": "tipoRenglónComponente.dimensiónComponente",

      "sentidoRenglónComponente": "sentidoRenglónComponente",

      "añoLineaBaseComponente": "lineaBaseRenglónComponente.añoLineaBaseComponente",
      "lineaBaseComponente": "lineaBaseRenglónComponente.lineaBaseComponente",

      "variableComponente1": "variablesFórmulaRenglónComponente.variableComponente1",
      "variableComponente2": "variablesFórmulaRenglónComponente.variableComponente2",

      "datosVariablesComponente1": "metasRenglónComponente.datosVariablesComponente1",
      "datosVariablesComponente2": "metasRenglónComponente.datosVariablesComponente2",
      "resultadoComponente": "metasRenglónComponente.resultadoComponente",

      "primerTrimestreRenglónComponente.datosComponente1": "primerTrimestreRenglónComponente.datosComponente1",
      "primerTrimestreRenglónComponente.datosComponente2": "primerTrimestreRenglónComponente.datosComponente2",
      "primerTrimestreRenglónComponente.resultadoComponente": "primerTrimestreRenglónComponente.resultadoComponente",

      "segundoTrimestreRenglónComponente.datosComponente1": "segundoTrimestreRenglónComponente.datosComponente1",
      "segundoTrimestreRenglónComponente.datosComponente2": "segundoTrimestreRenglónComponente.datosComponente2",
      "segundoTrimestreRenglónComponente.resultadoComponente": "segundoTrimestreRenglónComponente.resultadoComponente",

      "tercerTrimestreRenglónComponente.datosComponente1": "tercerTrimestreRenglónComponente.datosComponente1",
      "tercerTrimestreRenglónComponente.datosComponente2": "tercerTrimestreRenglónComponente.datosComponente2",
      "tercerTrimestreRenglónComponente.resultadoComponente": "tercerTrimestreRenglónComponente.resultadoComponente",

      "cuartoTrimestreRenglónComponente.datosComponente1": "cuartoTrimestreRenglónComponente.datosComponente1",
      "cuartoTrimestreRenglónComponente.datosComponente2": "cuartoTrimestreRenglónComponente.datosComponente2",
      "cuartoTrimestreRenglónComponente.resultadoComponente": "cuartoTrimestreRenglónComponente.resultadoComponente",

      "justificaciónResultadosRenglónComponente.análisisComponente": "justificaciónResultadosRenglónComponente.análisisComponente",
      "justificaciónResultadosRenglónComponente.mediosVerificaciónComponente": "justificaciónResultadosRenglónComponente.mediosVerificaciónComponente",

      "notasComplementariasComponente": "notasComplementariasComponente",
    };

    const path = fieldMap[field];
    if (path) {
      const pathParts = path.split(".");
      let current = currentSection;

      for (let i = 0; i < pathParts.length - 1; i++) {
        current = current[pathParts[i]];
      }

      current[pathParts[pathParts.length - 1]] = newValue;
    }

    setMirData(updatedMirData);
  };

  const handleSaveToDatabase = async () => {
    if (!isEditable) {
      setSnackbarMessage("AÚN NO SON LAS FECHAS PARA AGREGAR AVANCES");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (docPath && mirData) {
      const mirDocRef = doc(db, `${docPath}/Mir`, mirData.id);
      try {
        await setDoc(mirDocRef, mirData);
        setSnackbarMessage("¡Avance guardado exitosamente!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        generateAndDownloadPDF();

        setTimeout(() => {
          navigate("/directorymir");
        }, 3000);
      } catch (error) {
        console.error("Error al guardar en la base de datos:", error);
        setSnackbarMessage("Error al guardar los cambios.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const generateAndDownloadPDF = () => {
    const doc = new jsPDF();
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    let yOffset = 5;

    const logoWidthIzquierda = 28;
    const logoWidthDerecha = 50;
    const logoHeightIzquierda = 30;
    const logoHeightDerecha = 10;

    doc.addImage(
      logoIzquierda,
      "PNG",
      3,
      4,
      logoWidthIzquierda,
      logoHeightIzquierda
    );
    doc.addImage(
      logoDerecha,
      "PNG",
      pageWidth - logoWidthDerecha - 10,
      10,
      logoWidthDerecha,
      logoHeightDerecha
    );
    yOffset += Math.max(logoHeightIzquierda, logoHeightDerecha) + 5;

    const addCenteredText = (text, size, bold = false) => {
      doc.setFontSize(size);
      if (bold) doc.setFont("helvetica", "bold");
      else doc.setFont("helvetica", "normal");
      const textWidth =
        (doc.getStringUnitWidth(text) * size) / doc.internal.scaleFactor;
      const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(text, textOffset, yOffset);
      yOffset += 7;
      if (yOffset >= pageHeight - 10) {
        doc.addPage();
        yOffset = 20;
      }
    };

    addCenteredText("H AYUNTAMIENTO DE XALISCO", 14, true);
    addCenteredText(`${dependenciaPrograma.toUpperCase()}"`, 14, true);
    addCenteredText("MATRIZ DE INDICADORES DE RESULTADOS", 14, true);
    addCenteredText("COMPROBANTE DE AVANCE", 14, true);
    yOffset += 10;

    const tableData = [
      ["Fecha", formattedDate],
      ["Hora", formattedTime],
      ["Usuario", name],
      ["Programa", nombrePrograma.toUpperCase()],
      ["Dependencia", dependenciaPrograma.toUpperCase()],
      ["Nombre MIR", nombreMIR || "No definido"],
      ["Mensaje", "¡Se ha agregado un avance exitosamente!"],
    ];

    doc.autoTable({
      startY: yOffset,
      head: [["Campo", "Valor"]],
      body: tableData,
      styles: { font: "helvetica", fontSize: 13 },
      headStyles: { fillColor: [0, 0, 0] },
      columnStyles: { 0: { cellWidth: 40 }, 1: { cellWidth: 140 } },
      margin: { top: 10 },
    });

    doc.save(`Comprobante_Avance_${nombrePrograma.toUpperCase()}.pdf`);
  };

  return (
    <div className="avance-mir-container">
      <Header name={name} handleLogout={handleLogout} />
      <img
        src={logoIzquierda}
        alt="Logo Izquierdo"
        className="logo-izquierda"
        data-aos="fade-up"
      />
      <img
        src={logoDerecha}
        alt="Logo Derecho"
        className="logo-derecha"
        data-aos="fade-up"
      />
      <div className="avance-mir-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="zoom-in">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="avance-mir-title" data-aos="zoom-in">
        <h1>XLII AYUNTAMIENTO DE XALISCO</h1>
        <h1>MATRIZ DE INDICADORES DE RESULTADOS AVANCES</h1>
        <h3>PROGRAMA: {mirData.nombrePrograma?.toUpperCase()}</h3>
        <h3>UNIDAD RESPONSABLE: {dependenciaPrograma.toUpperCase()}</h3>
      </div>
      <div className="avance-mir-content" data-aos="fade-left" delay="1000">
        {mirData ? (
          <div className="avance-mir-grid">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveToDatabase}
            >
              Agregar avance
            </Button>

            <div className="mir-category">
              <h3>Renglón Fin</h3>
              {Array.isArray(mirData?.fin) &&
                mirData.fin.map((fin, index) => (
                  <>
                    <div className="mir-table-renglones">
                      <div className="mir-table-header">
                        <div className="mir-grid-header">
                          Objetivo o resumen narrativo
                        </div>
                        <div className="mir-grid-header">Nombre</div>
                        <div className="mir-grid-header">Fórmula</div>
                        <div className="mir-grid-header">Frecuencia y Tipo</div>
                        <div className="mir-grid-header">
                          Medios de verificación
                        </div>
                        <div className="mir-grid-header">Supuestos</div>
                      </div>
                      <div className="mir-table-row">
                        <div className="mir-grid-item">
                          {fin.objetivoRenglónFin}
                        </div>
                        <div className="mir-grid-item">
                          {fin.nombreRenglónFin}
                        </div>
                        <div className="mir-grid-item">
                          {fin.fórmulaRenglónFin}
                        </div>
                        <div className="mir-grid-item">
                          {fin.frecuenciaTipoRenglónFin}
                        </div>
                        <div className="mir-grid-item">
                          {fin.mediosVerificaciónRenglónFin}
                        </div>
                        <div className="mir-grid-item">
                          {fin.supuestosRenglónFin}
                        </div>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>U. De medida / Frecuencia</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Unidad de medida: </strong>
                              <EditableField
                                value={
                                  fin.unidadMedidaRenglónFin.unidadMedidaFin
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "unidadMedidaFin",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Frecuencia de medición: </strong>
                              <EditableField
                                value={fin.unidadMedidaRenglónFin.frecuenciaFin}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "frecuenciaFin",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Tipo / Dimensión</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Tipo: </strong>
                              <EditableField
                                value={fin.tipoRenglónFin.tipoFin}
                                onSave={(newValue) =>
                                  handleSave(index, "tipoFin", newValue, "fin")
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dimensión: </strong>
                              <EditableField
                                value={fin.tipoRenglónFin.dimensiónFin}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "dimensiónFin",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Sentido</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Sentido: </strong>
                              <EditableField
                                value={fin.sentidoRenglónFin}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "sentidoRenglónFin",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Línea base</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Año: </strong>
                              <EditableField
                                value={fin.lineaBaseRenglónFin.añoLineaBaseFin}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "añoLineaBaseFin",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Línea base: </strong>
                              <EditableField
                                value={fin.lineaBaseRenglónFin.lineaBaseFin}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "lineaBaseFin",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Variables de la fórmula</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Variable 1: </strong>
                              <EditableField
                                value={
                                  fin.variablesFórmulaRenglónFin.variableFin1
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "variableFin1",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Variable 2: </strong>
                              <EditableField
                                value={
                                  fin.variablesFórmulaRenglónFin.variableFin2
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "variableFin2",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Metas</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={fin.metasRenglónFin.datosVariablesFin1}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "datosVariablesFin1",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={fin.metasRenglónFin.datosVariablesFin2}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "datosVariablesFin2",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={fin.metasRenglónFin.resultadoFin}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "resultadoFin",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>1er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={fin.primerTrimestreRenglónFin.datosFin1}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestreRenglónFin.datosFin1",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={fin.primerTrimestreRenglónFin.datosFin2}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestreRenglónFin.datosFin2",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={
                                  fin.primerTrimestreRenglónFin.resultadoFin
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestreRenglónFin.resultadoFin",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>2do trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={fin.segundoTrimestreRenglónFin.datosFin1}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestreRenglónFin.datosFin1",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={fin.segundoTrimestreRenglónFin.datosFin2}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestreRenglónFin.datosFin2",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={
                                  fin.segundoTrimestreRenglónFin.resultadoFin
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestreRenglónFin.resultadoFin",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>3er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={fin.tercerTrimestreRenglónFin.datosFin1}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestreRenglónFin.datosFin1",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={fin.tercerTrimestreRenglónFin.datosFin2}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestreRenglónFin.datosFin2",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={
                                  fin.tercerTrimestreRenglónFin.resultadoFin
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestreRenglónFin.resultadoFin",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>4to trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={fin.cuartoTrimestreRenglónFin.datosFin1}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestreRenglónFin.datosFin1",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={fin.cuartoTrimestreRenglónFin.datosFin2}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestreRenglónFin.datosFin2",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={
                                  fin.cuartoTrimestreRenglónFin.resultadoFin
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestreRenglónFin.resultadoFin",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Justificación de resultados</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Análisis: </strong>
                              <EditableField
                                value={
                                  fin.justificaciónResultadosRenglónFin.análisisFin
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "justificaciónResultadosRenglónFin.análisisFin",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Medios de verificación: </strong>
                              <EditableField
                                value={
                                  fin.justificaciónResultadosRenglónFin
                                    .mediosVerificaciónFin
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "justificaciónResultadosRenglónFin.mediosVerificaciónFin",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Notas complementarias:</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Notas complementarias: </strong>
                              <EditableField
                                value={fin.notasComplementariasFin}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "notasComplementariasFin",
                                    newValue,
                                    "fin"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </>
                ))}
            </div>

            <div className="mir-category">
              <h3>Renglón Propósito</h3>
              {Array.isArray(mirData?.propósito) &&
                mirData.propósito.map((propósito, index) => (
                  <>
                    <div className="mir-table-renglones">
                      <div className="mir-table-header">
                        <div className="mir-grid-header">
                          Objetivo o resumen narrativo
                        </div>
                        <div className="mir-grid-header">Nombre</div>
                        <div className="mir-grid-header">Fórmula</div>
                        <div className="mir-grid-header">Frecuencia y Tipo</div>
                        <div className="mir-grid-header">
                          Medios de verificación
                        </div>
                        <div className="mir-grid-header">Supuestos</div>
                      </div>
                      <div className="mir-table-row">
                        <div className="mir-grid-item">
                          {propósito.objetivoRenglónPropósito}
                        </div>
                        <div className="mir-grid-item">
                          {propósito.nombreRenglónPropósito}
                        </div>
                        <div className="mir-grid-item">
                          {propósito.fórmulaRenglónPropósito}
                        </div>
                        <div className="mir-grid-item">
                          {propósito.frecuenciaTipoRenglónPropósito}
                        </div>
                        <div className="mir-grid-item">
                          {propósito.mediosVerificaciónRenglónPropósito}
                        </div>
                        <div className="mir-grid-item">
                          {propósito.supuestosRenglónPropósito}
                        </div>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>U. De medida / Frecuencia</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Unidad de medida: </strong>
                              <EditableField
                                value={
                                  propósito.unidadMedidaRenglónPropósito
                                    .unidadMedidaPropósito
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "unidadMedidaPropósito",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Frecuencia de medición: </strong>
                              <EditableField
                                value={
                                  propósito.unidadMedidaRenglónPropósito
                                    .frecuenciaPropósito
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "frecuenciaPropósito",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Tipo / Dimensión</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Tipo: </strong>
                              <EditableField
                                value={
                                  propósito.tipoRenglónPropósito.tipoPropósito
                                }
                                onSave={(newValue) =>
                                  handleSave(index, "tipoPropósito", newValue, "propósito")
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dimensión: </strong>
                              <EditableField
                                value={
                                  propósito.tipoRenglónPropósito
                                    .dimensiónPropósito
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "dimensiónPropósito",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Sentido</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Sentido: </strong>
                              <EditableField
                                value={propósito.sentidoRenglónPropósito}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "sentidoRenglónPropósito",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Línea base</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Año: </strong>
                              <EditableField
                                value={
                                  propósito.lineaBaseRenglónPropósito
                                    .añoLineaBasePropósito
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "añoLineaBasePropósito",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Línea base: </strong>
                              <EditableField
                                value={
                                  propósito.lineaBaseRenglónPropósito
                                    .lineaBasePropósito
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "lineaBasePropósito",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Variables de la fórmula</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Variable 1: </strong>
                              <EditableField
                                value={
                                  propósito.variablesFórmulaRenglónPropósito
                                    .variablePropósito1
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "variablePropósito1",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Variable 2: </strong>
                              <EditableField
                                value={
                                  propósito.variablesFórmulaRenglónPropósito
                                    .variablePropósito2
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "variablePropósito2",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Metas</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={
                                  propósito.metasRenglónPropósito
                                    .datosVariablesPropósito1
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "datosVariablesPropósito1",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={
                                  propósito.metasRenglónPropósito
                                    .datosVariablesPropósito2
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "datosVariablesPropósito2",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={
                                  propósito.metasRenglónPropósito
                                    .resultadoPropósito
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "resultadoPropósito",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>1er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={
                                  propósito.primerTrimestreRenglónPropósito
                                    .datosPropósito1
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestreRenglónPropósito.datosPropósito1",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={
                                  propósito.primerTrimestreRenglónPropósito
                                    .datosPropósito2
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestreRenglónPropósito.datosPropósito2",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={
                                  propósito.primerTrimestreRenglónPropósito
                                    .resultadoPropósito
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestreRenglónPropósito.resultadoPropósito",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>2do trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={
                                  propósito.segundoTrimestreRenglónPropósito
                                    .datosPropósito1
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestreRenglónPropósito.datosPropósito1",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={
                                  propósito.segundoTrimestreRenglónPropósito
                                    .datosPropósito2
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestreRenglónPropósito.datosPropósito2",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={
                                  propósito.segundoTrimestreRenglónPropósito
                                    .resultadoPropósito
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestreRenglónPropósito.resultadoPropósito",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>3er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={
                                  propósito.tercerTrimestreRenglónPropósito
                                    .datosPropósito1
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestreRenglónPropósito.datosPropósito1",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={
                                  propósito.tercerTrimestreRenglónPropósito
                                    .datosPropósito2
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestreRenglónPropósito.datosPropósito2",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={
                                  propósito.tercerTrimestreRenglónPropósito
                                    .resultadoPropósito
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestreRenglónPropósito.resultadoPropósito",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>4to trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={
                                  propósito.cuartoTrimestreRenglónPropósito
                                    .datosPropósito1
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestreRenglónPropósito.datosPropósito1",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={
                                  propósito.cuartoTrimestreRenglónPropósito
                                    .datosPropósito2
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestreRenglónPropósito.datosPropósito2",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={
                                  propósito.cuartoTrimestreRenglónPropósito
                                    .resultadoPropósito
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestreRenglónPropósito.resultadoPropósito",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Justificación de resultados</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Análisis: </strong>
                              <EditableField
                                value={
                                  propósito
                                    .justificaciónResultadosRenglónPropósito
                                    .análisisPropósito
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "justificaciónResultadosRenglónPropósito.análisisPropósito",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Medios de verificación: </strong>
                              <EditableField
                                value={
                                  propósito
                                    .justificaciónResultadosRenglónPropósito
                                    .mediosVerificaciónPropósito
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "justificaciónResultadosRenglónPropósito.mediosVerificaciónPropósito",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Notas complementarias:</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Notas complementarias: </strong>
                              <EditableField
                                value={propósito.notasComplementariasPropósito}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "notasComplementariasPropósito",
                                    newValue, "propósito"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </>
                ))}
            </div>

            <div className="mir-category">
              <h3>Renglón Componente</h3>
              {Array.isArray(mirData?.componente) &&
                mirData.componente.map((componente, index) => (
                  <>
                    <div className="mir-table-renglones">
                      <div className="mir-table-header">
                        <div className="mir-grid-header">
                          Objetivo o resumen narrativo
                        </div>
                        <div className="mir-grid-header">Nombre</div>
                        <div className="mir-grid-header">Fórmula</div>
                        <div className="mir-grid-header">Frecuencia y Tipo</div>
                        <div className="mir-grid-header">
                          Medios de verificación
                        </div>
                        <div className="mir-grid-header">Supuestos</div>
                      </div>
                      <div className="mir-table-row">
                        <div className="mir-grid-item">
                          {componente.objetivoRenglónComponente}
                        </div>
                        <div className="mir-grid-item">
                          {componente.nombreRenglónComponente}
                        </div>
                        <div className="mir-grid-item">
                          {componente.fórmulaRenglónComponente}
                        </div>
                        <div className="mir-grid-item">
                          {componente.frecuenciaTipoRenglónComponente}
                        </div>
                        <div className="mir-grid-item">
                          {componente.mediosVerificaciónRenglónComponente}
                        </div>
                        <div className="mir-grid-item">
                          {componente.supuestosRenglónComponente}
                        </div>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>U. De medida / Frecuencia</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Unidad de medida: </strong>
                              <EditableField
                                value={
                                  componente.unidadMedidaRenglónComponente
                                    .unidadMedidaComponente
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "unidadMedidaComponente",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Frecuencia de medición: </strong>
                              <EditableField
                                value={
                                  componente.unidadMedidaRenglónComponente
                                    .frecuenciaComponente
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "frecuenciaComponente",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Tipo / Dimensión</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Tipo: </strong>
                              <EditableField
                                value={
                                  componente.tipoRenglónComponente
                                    .tipoComponente
                                }
                                onSave={(newValue) =>
                                  handleSave(index, "tipoComponente", newValue, "componente")
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dimensión: </strong>
                              <EditableField
                                value={
                                  componente.tipoRenglónComponente
                                    .dimensiónComponente
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "dimensiónComponente",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Sentido</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Sentido: </strong>
                              <EditableField
                                value={componente.sentidoRenglónComponente}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "sentidoRenglónComponente",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Línea base</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Año: </strong>
                              <EditableField
                                value={
                                  componente.lineaBaseRenglónComponente
                                    .añoLineaBaseComponente
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "añoLineaBaseComponente",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Línea base: </strong>
                              <EditableField
                                value={
                                  componente.lineaBaseRenglónComponente
                                    .lineaBaseComponente
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "lineaBaseComponente",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Variables de la fórmula</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Variable 1: </strong>
                              <EditableField
                                value={
                                  componente.variablesFórmulaRenglónComponente
                                    .variableComponente1
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "variableComponente1",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Variable 2: </strong>
                              <EditableField
                                value={
                                  componente.variablesFórmulaRenglónComponente
                                    .variableComponente2
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "variableComponente2",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Metas</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={
                                  componente.metasRenglónComponente
                                    .datosVariablesComponente1
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "datosVariablesComponente1",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={
                                  componente.metasRenglónComponente
                                    .datosVariablesComponente2
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "datosVariablesComponente2",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={
                                  componente.metasRenglónComponente
                                    .resultadoComponente
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "resultadoComponente",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>1er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={
                                  componente.primerTrimestreRenglónComponente
                                    .datosComponente1
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestreRenglónComponente.datosComponente1",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={
                                  componente.primerTrimestreRenglónComponente
                                    .datosComponente2
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestreRenglónComponente.datosComponente2",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={
                                  componente.primerTrimestreRenglónComponente
                                    .resultadoComponente
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestreRenglónComponente.resultadoComponente",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>2do trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={
                                  componente.segundoTrimestreRenglónComponente
                                    .datosComponente1
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestreRenglónComponente.datosComponente1",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={
                                  componente.segundoTrimestreRenglónComponente
                                    .datosComponente2
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestreRenglónComponente.datosComponente2",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={
                                  componente.segundoTrimestreRenglónComponente
                                    .resultadoComponente
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestreRenglónComponente.resultadoComponente",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>3er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={
                                  componente.tercerTrimestreRenglónComponente
                                    .datosComponente1
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestreRenglónComponente.datosComponente1",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={
                                  componente.tercerTrimestreRenglónComponente
                                    .datosComponente2
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestreRenglónComponente.datosComponente2",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={
                                  componente.tercerTrimestreRenglónComponente
                                    .resultadoComponente
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestreRenglónComponente.resultadoComponente",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>4to trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={
                                  componente.cuartoTrimestreRenglónComponente
                                    .datosComponente1
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestreRenglónComponente.datosComponente1",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={
                                  componente.cuartoTrimestreRenglónComponente
                                    .datosComponente2
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestreRenglónComponente.datosComponente2",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={
                                  componente.cuartoTrimestreRenglónComponente
                                    .resultadoComponente
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestreRenglónComponente.resultadoComponente",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                    <div className="mir-table-renglones-avance">
                      <div className="mir-table-header">
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Justificación de resultados</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Análisis: </strong>
                              <EditableField
                                value={
                                  componente
                                    .justificaciónResultadosRenglónComponente
                                    .análisisComponente
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "justificaciónResultadosRenglónComponente.análisisComponente",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Medios de verificación: </strong>
                              <EditableField
                                value={
                                  componente
                                    .justificaciónResultadosRenglónComponente
                                    .mediosVerificaciónComponente
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "justificaciónResultadosRenglónComponente.mediosVerificaciónComponente",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Notas complementarias:</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Notas complementarias: </strong>
                              <EditableField
                                value={
                                  componente.notasComplementariasComponente
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "notasComplementariasComponente",
                                    newValue, "componente"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </>
                ))}
            </div>

            <div className="avance-mir-actividades">
              <h3>Actividades</h3>
              {Array.isArray(mirData?.actividades) &&
                mirData.actividades.map((actividad, index) => (
                  <Accordion key={index} className="avance-mir-accordion" expanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className="mir-accordion-summary"
                    >
                      <Typography>Actividad No. {index + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="mir-accordion-details">
                      <div className="actividad">
                        <div className="mir-grid-item">
                          <strong> Nombre: </strong>
                          {actividad.nombreActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Objetivo: </strong>
                          {actividad.objetivoActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Fórmula: </strong>{" "}
                          {actividad.fórmulaActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Frecuencia y Tipo: </strong>{" "}
                          {actividad.frecuenciaTipoActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Medios de verificación: </strong>{" "}
                          {actividad.mediosVerificaciónActividad || "N/A"}
                        </div>
                        <div className="mir-grid-item">
                          <strong> Supuestos: </strong>{" "}
                          {actividad.supuestosActividad || "N/A"}
                        </div>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>U. De medida / Frecuencia</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Unidad de medida: </strong>
                              <EditableField
                                value={
                                  actividad.unidadMedidaActividad.unidadMedida
                                }
                                onSave={(newValue) =>
                                  handleSave(index, "unidadMedida", newValue, "actividades")
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Frecuencia de medición: </strong>
                              <EditableField
                                value={
                                  actividad.unidadMedidaActividad.frecuencia
                                }
                                onSave={(newValue) =>
                                  handleSave(index, "frecuencia", newValue, "actividades")
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Tipo / Dimensión</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Tipo: </strong>
                              <EditableField
                                value={actividad.tipoActividad.tipo}
                                onSave={(newValue) =>
                                  handleSave(index, "tipo", newValue, "actividades")
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dimensión: </strong>
                              <EditableField
                                value={actividad.tipoActividad.dimensión}
                                onSave={(newValue) =>
                                  handleSave(index, "dimensión", newValue, "actividades")
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <div className="mir-grid-item">
                          <strong> Sentido: </strong>
                          <EditableField
                            value={actividad.sentidoActividad}
                            onSave={(newValue) =>
                              handleSave(index, "sentidoActividad", newValue, "actividades")
                            }
                            editable={isEditable}
                            isText={true}
                          />
                        </div>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Línea base</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Año: </strong>
                              <EditableField
                                value={
                                  actividad.lineaBaseActividad.añoLineaBase
                                }
                                onSave={(newValue) =>
                                  handleSave(index, "añoLineaBase", newValue, "actividades")
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Línea base: </strong>
                              <EditableField
                                value={actividad.lineaBaseActividad.lineaBase}
                                onSave={(newValue) =>
                                  handleSave(index, "lineaBase", newValue, "actividades")
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Variables de la fórmula</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Variable 1: </strong>
                              <EditableField
                                value={
                                  actividad.variablesFórmulaActividades
                                    .variableActividad1
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "variableActividad1",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Variable 2: </strong>
                              <EditableField
                                value={
                                  actividad.variablesFórmulaActividades
                                    .variableActividad2
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "variableActividad2",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Metas</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={
                                  actividad.metasActividades.datosVariables1
                                }
                                onSave={(newValue) =>
                                  handleSave(index, "datosVariables1", newValue, "actividades")
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={
                                  actividad.metasActividades.datosVariables2
                                }
                                onSave={(newValue) =>
                                  handleSave(index, "datosVariables2", newValue, "actividades")
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={actividad.metasActividades.resultado}
                                onSave={(newValue) =>
                                  handleSave(index, "resultado", newValue, "actividades")
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>1er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={actividad.primerTrimestre.datos1}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestre.datos1",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={actividad.primerTrimestre.datos2}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestre.datos2",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={actividad.primerTrimestre.resultado}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "primerTrimestre.resultado",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>2do trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={actividad.segundoTrimestre.datos1}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestre.datos1",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={actividad.segundoTrimestre.datos2}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestre.datos2",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={actividad.segundoTrimestre.resultado}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "segundoTrimestre.resultado",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>3er trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={actividad.tercerTrimestre.datos1}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestre.datos1",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={actividad.tercerTrimestre.datos2}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestre.datos2",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={actividad.tercerTrimestre.resultado}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "tercerTrimestre.resultado",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>4to trimestre</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Dato 1: </strong>
                              <EditableField
                                value={actividad.cuartoTrimestre.datos1}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestre.datos1",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Dato 2: </strong>
                              <EditableField
                                value={actividad.cuartoTrimestre.datos2}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestre.datos2",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Resultado: </strong>
                              <EditableField
                                value={actividad.cuartoTrimestre.resultado}
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "cuartoTrimestre.resultado",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="mir-accordion" expanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className="mir-accordion-summary"
                          >
                            <Typography>Justificación de resultados</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mir-grid-item">
                              <strong> Análisis: </strong>
                              <EditableField
                                value={
                                  actividad.justificaciónResultados.análisis
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "justificaciónResultados.análisis",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                            <div className="mir-grid-item">
                              <strong> Medios de verificación: </strong>
                              <EditableField
                                value={
                                  actividad.justificaciónResultados
                                    .mediosVerificación
                                }
                                onSave={(newValue) =>
                                  handleSave(
                                    index,
                                    "justificaciónResultados.mediosVerificación",
                                    newValue, "actividades"
                                  )
                                }
                                editable={isEditable}
                                isText={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <div className="mir-grid-item">
                          <strong> Notas complementarias: </strong>
                          <EditableField
                            value={actividad.notasComplementarias}
                            onSave={(newValue) =>
                              handleSave(
                                index,
                                "notasComplementarias",
                                newValue, "actividades"
                              )
                            }
                            editable={isEditable}
                            isText={true}
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          </div>
        ) : (
          <p>Aún no se ha agregado ningún Programa Anual de Trabajo</p>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AvanceMIR;
