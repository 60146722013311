import React, { useState, useEffect } from "react";
import { db } from "./firebase.js";
import { collection, getDocs, doc, getDoc, setDoc } from "firebase/firestore";
import { TextField, Button, Snackbar, Alert } from "@mui/material";
import { FaHome } from "react-icons/fa";
import { FaBuildingCircleArrowRight } from "react-icons/fa6";
import { PiSecurityCameraFill } from "react-icons/pi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { MdFamilyRestroom } from "react-icons/md";
import { FaUserAlt, FaUserPlus } from "react-icons/fa";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import "./Css/AdminPanel.css";
import Header from "./Components/Header.js";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import logoPanelAdmin from "./Images/SIRAX_logo.png";
import { Oval } from "react-loader-spinner";

function AdminPanel() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [name, setName] = useState("");
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pats, setPats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
    } else {
      navigate("/login");
    }
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "Usuarios"));
      const usersData = [];
      querySnapshot.forEach((doc) => {
        usersData.push(doc.data());
      });
      setUsers(usersData);
    };
    const fetchDates = async () => {
      const docRef = doc(db, "admin", "editDates");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setStartDate(data.startDate);
        setEndDate(data.endDate);
      }
    };
    const fetchPats = async () => {
      const ejes = ["EjeRector1", "EjeRector2", "EjeRector3", "EjeRector4"];
      let allPats = [];
      for (const eje of ejes) {
        const ejeSnapshot = await getDocs(collection(db, eje));
        for (const doc of ejeSnapshot.docs) {
          const lineaAccionSnapshot = await getDocs(
            collection(db, `${eje}/${doc.id}/LineaAccion`)
          );
          for (const lineaAccionDoc of lineaAccionSnapshot.docs) {
            const patSnapshot = await getDocs(
              collection(
                db,
                `${eje}/${doc.id}/LineaAccion/${lineaAccionDoc.id}/Pat`
              )
            );
            for (const patDoc of patSnapshot.docs) {
              allPats.push(`${patDoc.data().ejeRectorPMD} ${patDoc.data().lineaAccionPMD} ${patDoc.data().programaPMD} (${patDoc.data().nombrePAT})`);
            }
          }
        }
      }
      setPats(allPats);
      setLoading(false);
    };
    fetchUsers();
    fetchDates();
    fetchPats();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("userName");
    navigate("/login");
  };

  const handleSaveDates = async () => {
    const docRef = doc(db, "admin", "editDates");
    await setDoc(docRef, { startDate, endDate });
    setSnackbarMessage("¡Fechas asignadas exitosamente!");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .replace(/\s+/g, "");
  };

  const filteredUsers = users.filter((user) => {
    const normalizedSearchTerm = normalizeString(searchTerm);
    const normalizedUserName = user.name ? normalizeString(user.name) : "";
    const normalizedUserDependencia = user.dependencia
      ? normalizeString(user.dependencia)
      : "";
    return (
      normalizedUserName.includes(normalizedSearchTerm) ||
      normalizedUserDependencia.includes(normalizedSearchTerm)
    );
  });

  const handleMenuClick = (menu) => {
    setExpandedMenu(expandedMenu === menu ? null : menu);
  };

  const handleInicioMIRClick = () => {
    navigate("/directorymir");
  };

  const handleInicioPATClick = () => {
    navigate("/directorypat");
  };

  const handleEjeR1Click = () => {
    navigate("/buen-gobierno");
  };

  const handleEjeR2Click = () => {
    navigate("/xalisco-desarrollo");
  };

  const handleEjeR3Click = () => {
    navigate("/crecimiento-economico");
  };

  const handleEjeR4Click = () => {
    navigate("/xalisco-cerca-ti");
  };

  const handleRegisterClick = () => {
    navigate("/register");
  };

  return (
    <div className="admin-panel">
      <div className="sidebar" data-aos="fade-down">
        <div className="logo-panel-admin-container">
          <img
            src={logoPanelAdmin}
            alt="logoPanelAdmin"
            className="logoPanelAdmin"
          />
          <h2>SIRAX</h2>
        </div>
        <nav>
          <ul>
            <li onClick={handleInicioMIRClick}>
              <FaHome className="sidebar-icon" />
              Inicio MIR
            </li>
            <li onClick={handleInicioPATClick}>
              <FaHome className="sidebar-icon" />
              Inicio PAT
            </li>
            <li onClick={() => handleMenuClick("usuarios")}>
              <FaUserAlt className="sidebar-icon" />
              Usuarios
              {expandedMenu === "usuarios" ? (
                <IoIosArrowDown className="arrow-icon" />
              ) : (
                <IoIosArrowForward className="arrow-icon" />
              )}
            </li>
            {expandedMenu === "usuarios" && (
              <ul className="submenu" data-aos="fade-right">
                <li onClick={handleRegisterClick}>
                  <FaUserPlus className="sidebar-icon" />
                  Crear usuario
                </li>
              </ul>
            )}
            <li onClick={() => handleMenuClick("eje1")}>
              <PiSecurityCameraFill className="sidebar-icon" />
              Eje Rector 1
              {expandedMenu === "eje1" ? (
                <IoIosArrowDown className="arrow-icon" />
              ) : (
                <IoIosArrowForward className="arrow-icon" />
              )}
            </li>
            {expandedMenu === "eje1" && (
              <ul
                className="submenu"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <li onClick={handleEjeR1Click}>
                  <IoDocuments className="sidebar-icon" />
                  Programas
                </li>
              </ul>
            )}
            <li onClick={() => handleMenuClick("eje2")}>
              <FaBuildingCircleArrowRight className="sidebar-icon" />
              Eje Rector 2
              {expandedMenu === "eje2" ? (
                <IoIosArrowDown className="arrow-icon" />
              ) : (
                <IoIosArrowForward className="arrow-icon" />
              )}
            </li>
            {expandedMenu === "eje2" && (
              <ul
                className="submenu"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <li onClick={handleEjeR2Click}>
                  <IoDocuments className="sidebar-icon" />
                  Programas
                </li>
              </ul>
            )}
            <li onClick={() => handleMenuClick("eje3")}>
              <FaMoneyBillTrendUp className="sidebar-icon" />
              Eje Rector 3
              {expandedMenu === "eje3" ? (
                <IoIosArrowDown className="arrow-icon" />
              ) : (
                <IoIosArrowForward className="arrow-icon" />
              )}
            </li>
            {expandedMenu === "eje3" && (
              <ul
                className="submenu"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <li onClick={handleEjeR3Click}>
                  <IoDocuments className="sidebar-icon" />
                  Programas
                </li>
              </ul>
            )}
            <li onClick={() => handleMenuClick("eje4")}>
              <MdFamilyRestroom className="sidebar-icon" />
              Eje Rector 4
              {expandedMenu === "eje4" ? (
                <IoIosArrowDown className="arrow-icon" />
              ) : (
                <IoIosArrowForward className="arrow-icon" />
              )}
            </li>
            {expandedMenu === "eje4" && (
              <ul
                className="submenu"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <li onClick={handleEjeR4Click}>
                  <IoDocuments className="sidebar-icon" />
                  Programas
                </li>
              </ul>
            )}
          </ul>
        </nav>
      </div>
      <div className="admin-main-content">
        <Header name={name} handleLogout={handleLogout} />
        <div className="content">
          <div className="flex-container">
            <div className="left-container">
              <div className="date-picker-box" data-aos="fade-down">
                <h2>Configurar fechas para agregar avances</h2>
                <div className="date-picker-container">
                  <TextField
                    label="Fecha de Inicio"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    label="Fecha de Fin"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveDates}
                >
                  Agregar Fechas
                </Button>
              </div>
              <div className="view-pat-box" data-aos="fade-down">
                <h2>Lista de Planes Anuales de Trabajo</h2>
                {loading ? (
                  <div className="loading-container">
                    <Oval
                      height={40}
                      width={40}
                      color="#4caf50"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#f0f2f2"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                    <p>Cargando Pats...</p>
                  </div>
                ) : (
                  <ul>
                    {pats.map((pat, index) => (
                      <li key={index}>{pat}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="user-list-box" data-aos="fade-down">
              <h2>Lista de Usuarios</h2>
              <div className="search-bar-container">
                <TextField
                  label="Buscar usuario"
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Usuario</th>
                    <th>Correo</th>
                    <th>Dependencia</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user, index) => (
                    <tr key={index}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.dependencia}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
}

export default AdminPanel;
