import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import Register from "./Register";
import ForgotP from "./ForgotPassword";
import Directorypat from "./Directorypat";
import EjeR1PAT from "./EjeR1PAT";
import EjeR2PAT from "./EjeR2PAT";
import EjeR3PAT from "./EjeR3PAT";
import EjeR4PAT from "./EjeR4PAT";
import FormularioPAT from "./FormularioPAT";
import VerPAT from "./VerPat";
import AvacePAT from "./AvancePAT";
import AdminPanel from "./AdminPanel";
import PrivateRoute from "./Components/PrivateRoute";
import ResetPassword from "./ResetPassword";
import Directorymir from "./Directorymir";
import EjeR1MIR from "./EjeR1MIR";
import EjeR2MIR from "./EjeR2MIR";
import EjeR3MIR from "./EjeR3MIR";
import EjeR4MIR from "./EjeR4MIR";
import EjeT1MIR from "./EjeT1MIR";
import EjeT2MIR from "./EjeT2MIR";
import EjeT3MIR from "./EjeT3MIR";
import FormularioMIR from "./FormularioMIR";
import VerMIR from "./VerMIR";
import AvanceMIR from "./AvanceMIR";
import "./Css/App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotP />} />
          <Route path="/directorypat" element={<Directorypat />} />
          <Route path="/EjeR1PAT" element={<EjeR1PAT />} />
          <Route path="/EjeR2PAT" element={<EjeR2PAT />} />
          <Route path="/EjeR3PAT" element={<EjeR3PAT />} />
          <Route path="/EjeR4PAT" element={<EjeR4PAT />} />
          <Route path="/EjeR1MIR" element={<EjeR1MIR />} />
          <Route path="/EjeR2MIR" element={<EjeR2MIR />} />
          <Route path="/EjeR3MIR" element={<EjeR3MIR />} />
          <Route path="/EjeR4MIR" element={<EjeR4MIR />} />
          <Route path="/EjeT1MIR" element={<EjeT1MIR />} />
          <Route path="/EjeT2MIR" element={<EjeT2MIR />} />
          <Route path="/EjeT3MIR" element={<EjeT3MIR />} />
          <Route path="/formulario-pat" element={<FormularioPAT />} />
          <Route path="/formulario-mir" element={<FormularioMIR />} />
          <Route path="/ver-pat/:patId" element={<VerPAT />} />
          <Route path="/ver-mir/:mirId" element={<VerMIR />} />
          <Route path="/avance-pat/:patId" element={<AvacePAT />} />
          <Route path="/avance-mir/:mirId" element={<AvanceMIR />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/directorymir" element={<Directorymir />} />
          <Route element={<PrivateRoute allowedRoles={["admin"]} />}>
            <Route path="/admin-panel" element={<AdminPanel />} />
            <Route path="/register" element={<Register />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
