import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { db } from "./firebase";
import "./Css/FormularioMIR.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { collection, addDoc } from "firebase/firestore";
import Header from "./Components/Header.js";
import {
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Snackbar,
  Alert,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logoIzquierda from "./Images/LogoAyuntamiento.png";
import logoDerecha from "./Images/LogoImplanNuevo.jpeg";

function FormularioMIR() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const {
    dependenciaPrograma,
    docPath,
    ejeRector,
    temaDesarrollo,
    objetivoPrograma,
    nombrePrograma,
  } = location.state;

  const [formData, setFormData] = useState({
    /* Datos del programa*/
    dependenciaPrograma: dependenciaPrograma || "",
    nombrePrograma: nombrePrograma || "",
    ejeRector: ejeRector || "",
    temaDesarrollo: temaDesarrollo || "",
    objetivoPrograma: objetivoPrograma || "",

    /* Datos del renglón fin*/
    fin: [],

    /* Datos del renglón propósito*/
    propósito: [],

    /* Datos del renglón componentes*/
    componente: [],

    /* Datos de las actividades */
    actividades: [],

    /* Datos de los responsables */
    responsableElaboracion: "",
    titularDependencia: "",
    userName: "",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [nombreMIR, setNombreMIR] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
      setFormData((prevData) => ({
        ...prevData,
        userName: storedName,
      }));
    } else {
      navigate("/login");
    }

    setFormData((prevData) => ({
      ...prevData,
      ejeRector: ejeRector || "",
      temaDesarrollo: temaDesarrollo || "",
      objetivoPrograma: objetivoPrograma || "",
    }));

    const fetchData = async () => {};

    fetchData();
  }, [
    navigate,
    dependenciaPrograma,
    docPath,
    ejeRector,
    temaDesarrollo,
    objetivoPrograma,
  ]);

  const handleLogout = () => {
    localStorage.removeItem("userName");
    navigate("/login");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setConfirmDialogOpen(false);
    e.preventDefault();
    try {
      const mirCollectionRef = collection(db, `${docPath}/Mir`);
      await addDoc(mirCollectionRef, { ...formData, nombreMIR });
      setSnackbarMessage(
        "¡MATRIZ DE INDICADORES DE RESULTADOS (MIR) guardado exitosamente!"
      );
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate("/directorymir");
      }, 3200);
    } catch (error) {
      console.error("Error guardando los datos: ", error);
      setSnackbarMessage("Error guardando los datos");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleBackClick = () => {
    navigate("/directorymir");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  /* Funciones para el renglón actividades */
  const agregarActividad = () => {
    setFormData((prevData) => ({
      ...prevData,
      actividades: [
        ...prevData.actividades,
        {
          objetivoActividad: "",
          nombreActividad: "",
          fórmulaActividad: "",
          frecuenciaTipoActividad: "",
          mediosVerificaciónActividad: "",
          supuestosActividad: "",
          unidadMedidaActividad: {
            unidadMedida: "",
            frecuencia: "",
          },
          tipoActividad: {
            tipo: "",
            dimensión: "",
          },
          sentidoActividad: "",
          lineaBaseActividad: {
            añoLineaBase: "",
            lineaBase: "",
          },
          variablesFórmulaActividades: {
            variableActividad1: "",
            variableActividad2: "",
          },
          metasActividades: {
            datosVariables1: "",
            datosVariables2: "",
            resultado: "",
          },
          primerTrimestre: {
            datos1: "",
            datos2: "",
            resultado: "",
          },
          segundoTrimestre: {
            datos1: "",
            datos2: "",
            resultado: "",
          },
          tercerTrimestre: {
            datos1: "",
            datos2: "",
            resultado: "",
          },
          cuartoTrimestre: {
            datos1: "",
            datos2: "",
            resultado: "",
          },
          justificaciónResultados: {
            análisis: "",
            mediosVerificación: "",
          },
          notasComplementarias: "",
        },
      ],
    }));
  };

  const eliminarActividad = (index) => {
    setFormData((prevData) => {
      const nuevasActividades = [...prevData.actividades];
      nuevasActividades.splice(index, 1);
      return {
        ...prevData,
        actividades: nuevasActividades,
      };
    });
  };

  const handleActividadChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const nuevasActividades = [...prevData.actividades];
      const keys = name.split(".");
      if (keys.length > 1) {
        // Campo anidado
        nuevasActividades[index] = {
          ...nuevasActividades[index],
          [keys[0]]: {
            ...nuevasActividades[index][keys[0]],
            [keys[1]]: value,
          },
        };
      } else {
        // Campo simple
        nuevasActividades[index] = {
          ...nuevasActividades[index],
          [name]: value,
        };
      }
      return {
        ...prevData,
        actividades: nuevasActividades,
      };
    });
  };

  const handleConfirmDialogOpen = () => {
    console.log("Abriendo diálogo...");
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleNombreMIRChange = (e) => {
    setNombreMIR(e.target.value);
  };

  /* Funciones para el renglón fin */
  const agregarRenglónFin = () => {
    setFormData((prevData) => ({
      ...prevData,
      fin: [
        ...prevData.fin,
        {
          objetivoRenglónFin: "",
          nombreRenglónFin: "",
          fórmulaRenglónFin: "",
          frecuenciaTipoRenglónFin: "",
          mediosVerificaciónRenglónFin: "",
          supuestosRenglónFin: "",
          unidadMedidaRenglónFin: {
            unidadMedidaFin: "",
            frecuenciaFin: "",
          },
          tipoRenglónFin: {
            tipoFin: "",
            dimensiónFin: "",
          },
          sentidoRenglónFin: "",
          lineaBaseRenglónFin: {
            añoLineaBaseFin: "",
            lineaBaseFin: "",
          },
          variablesFórmulaRenglónFin: {
            variableFin1: "",
            variableFin2: "",
          },
          metasRenglónFin: {
            datosVariablesFin1: "",
            datosVariablesFin2: "",
            resultadoFin: "",
          },
          primerTrimestreRenglónFin: {
            datosFin1: "",
            datoFin2: "",
            resultadoFin: "",
          },
          segundoTrimestreRenglónFin: {
            datosFin1: "",
            datosFin2: "",
            resultadoFin: "",
          },
          tercerTrimestreRenglónFin: {
            datosFin1: "",
            datosFin2: "",
            resultadoFin: "",
          },
          cuartoTrimestreRenglónFin: {
            datosFin1: "",
            datosFin2: "",
            resultadoFin: "",
          },
          justificaciónResultadosRenglónFin: {
            análisisFin: "",
            mediosVerificaciónFin: "",
          },
          notasComplementariasFin: "",
        },
      ],
    }));
  };

  const eliminarRenglónFin = (index) => {
    setFormData((prevData) => {
      const nuevoFin = [...prevData.fin];
      nuevoFin.splice(index, 1);
      return {
        ...prevData,
        fin: nuevoFin,
      };
    });
  };

  const handleRenglónFinChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const nuevoFin = [...prevData.fin];
      const keys = name.split(".");
      if (keys.length > 1) {
        // Campo anidado
        nuevoFin[index] = {
          ...nuevoFin[index],
          [keys[0]]: {
            ...nuevoFin[index][keys[0]],
            [keys[1]]: value,
          },
        };
      } else {
        // Campo simple
        nuevoFin[index] = {
          ...nuevoFin[index],
          [name]: value,
        };
      }
      return {
        ...prevData,
        fin: nuevoFin,
      };
    });
  };

  /* Funciones para el renglón propósito */
  const agregarRenglónPropósito = () => {
    setFormData((prevData) => ({
      ...prevData,
      propósito: [
        ...prevData.propósito,
        {
          objetivoRenglónPropósito: "",
          nombreRenglónPropósito: "",
          fórmulaRenglónPropósito: "",
          frecuenciaTipoRenglónPropósito: "",
          mediosVerificaciónRenglónPropósito: "",
          supuestosRenglónPropósito: "",
          unidadMedidaRenglónPropósito: {
            unidadMedidaPropósito: "",
            frecuenciaPropósito: "",
          },
          tipoRenglónPropósito: {
            tipoPropósito: "",
            dimensiónPropósito: "",
          },
          sentidoRenglónPropósito: "",
          lineaBaseRenglónPropósito: {
            añoLineaBasePropósito: "",
            lineaBasePropósito: "",
          },
          variablesFórmulaRenglónPropósito: {
            variablePropósito1: "",
            variablePropósito2: "",
          },
          metasRenglónPropósito: {
            datosVariablesPropósito1: "",
            datosVariablesPropósito2: "",
            resultadoPropósito: "",
          },
          primerTrimestreRenglónPropósito: {
            datosPropósito1: "",
            datoPropósito2: "",
            resultadoPropósito: "",
          },
          segundoTrimestreRenglónPropósito: {
            datosPropósito1: "",
            datosPropósito2: "",
            resultadoPropósito: "",
          },
          tercerTrimestreRenglónPropósito: {
            datosPropósito1: "",
            datosPropósito2: "",
            resultadoPropósito: "",
          },
          cuartoTrimestreRenglónPropósito: {
            datosPropósito1: "",
            datosPropósito2: "",
            resultadoPropósito: "",
          },
          justificaciónResultadosRenglónPropósito: {
            análisisPropósito: "",
            mediosVerificaciónPropósito: "",
          },
          notasComplementariasPropósito: "",
        },
      ],
    }));
  };

  const eliminarRenglónPropósito = (index) => {
    setFormData((prevData) => {
      const nuevoPropósito = [...prevData.propósito];
      nuevoPropósito.splice(index, 1);
      return {
        ...prevData,
        propósito: nuevoPropósito,
      };
    });
  };

  const handleRenglónPropósitoChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const nuevoPropósito = [...prevData.propósito];
      const keys = name.split(".");
      if (keys.length > 1) {
        // Campo anidado
        nuevoPropósito[index] = {
          ...nuevoPropósito[index],
          [keys[0]]: {
            ...nuevoPropósito[index][keys[0]],
            [keys[1]]: value,
          },
        };
      } else {
        // Campo simple
        nuevoPropósito[index] = {
          ...nuevoPropósito[index],
          [name]: value,
        };
      }
      return {
        ...prevData,
        propósito: nuevoPropósito,
      };
    });
  };

  /* Funciones para el renglón componente */
  const agregarRenglónComponente = () => {
    setFormData((prevData) => ({
      ...prevData,
      componente: [
        ...prevData.componente,
        {
          objetivoRenglónComponente: "",
          nombreRenglónComponente: "",
          fórmulaRenglónComponente: "",
          frecuenciaTipoRenglónComponente: "",
          mediosVerificaciónRenglónComponente: "",
          supuestosRenglónComponente: "",
          unidadMedidaRenglónComponente: {
            unidadMedidaComponente: "",
            frecuenciaComponente: "",
          },
          tipoRenglónComponente: {
            tipoComponente: "",
            dimensiónComponente: "",
          },
          sentidoRenglónComponente: "",
          lineaBaseRenglónComponente: {
            añoLineaBaseComponente: "",
            lineaBaseComponente: "",
          },
          variablesFórmulaRenglónComponente: {
            variableComponente1: "",
            variableComponente2: "",
          },
          metasRenglónComponente: {
            datosVariablesComponente1: "",
            datosVariablesComponente2: "",
            resultadoComponente: "",
          },
          primerTrimestreRenglónComponente: {
            datosComponente1: "",
            datosComponente2: "",
            resultadoComponente: "",
          },
          segundoTrimestreRenglónComponente: {
            datosComponente1: "",
            datosComponente2: "",
            resultadoComponente: "",
          },
          tercerTrimestreRenglónComponente: {
            datosComponente1: "",
            datosComponente2: "",
            resultadoComponente: "",
          },
          cuartoTrimestreRenglónComponente: {
            datosComponente1: "",
            datosComponente2: "",
            resultadoComponente: "",
          },
          justificaciónResultadosRenglónComponente: {
            análisisComponente: "",
            mediosVerificaciónComponente: "",
          },
          notasComplementariasComponente: "",
        },
      ],
    }));
  };

  const eliminarRenglónComponente = (index) => {
    setFormData((prevData) => {
      const nuevoComponente = [...prevData.componente];
      nuevoComponente.splice(index, 1);
      return {
        ...prevData,
        componente: nuevoComponente,
      };
    });
  };

  const handleRenglónComponenteChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const nuevoComponente = [...prevData.componente];
      const keys = name.split(".");
      if (keys.length > 1) {
        // Campo anidado
        nuevoComponente[index] = {
          ...nuevoComponente[index],
          [keys[0]]: {
            ...nuevoComponente[index][keys[0]],
            [keys[1]]: value,
          },
        };
      } else {
        // Campo simple
        nuevoComponente[index] = {
          ...nuevoComponente[index],
          [name]: value,
        };
      }
      return {
        ...prevData,
        componente: nuevoComponente,
      };
    });
  };

  return (
    <div className="formulario-mir-container">
      <Header name={name} handleLogout={handleLogout} />
      <img
        src={logoIzquierda}
        alt="Logo Izquierdo"
        className="logo-izquierda"
        data-aos="fade-up"
      />
      <img
        src={logoDerecha}
        alt="Logo Derecho"
        className="logo-derecha"
        data-aos="fade-up"
      />
      <div className="formulario-mir-back-button-container">
        <IconButton onClick={handleBackClick} data-aos="fade-up">
          <ArrowBack />
        </IconButton>
      </div>
      <div className="formulario-mir-title" data-aos="zoom-in-up">
        <h1>XLII AYUNTAMIENTO DE XALISCO</h1>
        <h1>MATRIZ DE INDICADORES DE RESULTADOS</h1>
        <h3>PROGRAMA: {nombrePrograma.toUpperCase()}</h3>
        <h3>UNIDAD RESPONSABLE: {dependenciaPrograma.toUpperCase()}</h3>
      </div>
      <form
        onSubmit={handleSubmit}
        className="formulario-mir-form"
        data-aos="fade-up"
        delay="1000"
      >
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>RENGLÓN FIN</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="columna">
              {formData.fin.map((fin, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Renglón Fin {index + 1}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="columna">
                      <label>OBJETIVO O RESUMEN NARRATIVO:</label>
                      <textarea
                        name="objetivoRenglónFin"
                        value={fin.objetivoRenglónFin}
                        onChange={(e) => handleRenglónFinChange(e, index)}
                        required
                      />
                      <label>NOMBRE:</label>
                      <textarea
                        name="nombreRenglónFin"
                        value={fin.nombreRenglónFin}
                        onChange={(e) => handleRenglónFinChange(e, index)}
                        required
                      />
                      <label>FÓRMULA:</label>
                      <textarea
                        name="fórmulaRenglónFin"
                        value={fin.fórmulaRenglónFin}
                        onChange={(e) => handleRenglónFinChange(e, index)}
                        required
                      />
                      <label>FRECUENCIA Y TIPO:</label>
                      <textarea
                        name="frecuenciaTipoRenglónFin"
                        value={fin.frecuenciaTipoRenglónFin}
                        onChange={(e) => handleRenglónFinChange(e, index)}
                        required
                      />
                      <label>MEDIOS DE VERIFICACIÓN:</label>
                      <textarea
                        name="mediosVerificaciónRenglónFin"
                        value={fin.mediosVerificaciónRenglónFin}
                        onChange={(e) => handleRenglónFinChange(e, index)}
                        required
                      />
                      <label>SUPUESTOS:</label>
                      <textarea
                        name="supuestosRenglónFin"
                        value={fin.supuestosRenglónFin}
                        onChange={(e) => handleRenglónFinChange(e, index)}
                        required
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
              <div className="formulario-mir-buttons">
                <div className="formulario-mir-add-activity">
                  <button type="button" onClick={agregarRenglónFin}>
                    Agregar renglón fin
                  </button>
                </div>
                <div className="formulario-mir-delete-activity">
                  <button type="button" onClick={eliminarRenglónFin}>
                    Borrar renglón fin
                  </button>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>RENGLÓN PROPÓSITO</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="columna">
              {formData.propósito.map((propósito, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Renglón Propósito {index + 1}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="columna">
                      <label>OBJETIVO O RESUMEN NARRATIVO:</label>
                      <textarea
                        name="objetivoRenglónPropósito"
                        value={propósito.objetivoRenglónPropósito}
                        onChange={(e) => handleRenglónPropósitoChange(e, index)}
                        required
                      />
                      <label>NOMBRE:</label>
                      <textarea
                        name="nombreRenglónPropósito"
                        value={propósito.nombreRenglónPropósito}
                        onChange={(e) => handleRenglónPropósitoChange(e, index)}
                        required
                      />
                      <label>FÓRMULA:</label>
                      <textarea
                        name="fórmulaRenglónPropósito"
                        value={propósito.fórmulaRenglónPropósito}
                        onChange={(e) => handleRenglónPropósitoChange(e, index)}
                        required
                      />
                      <label>FRECUENCIA Y TIPO:</label>
                      <textarea
                        name="frecuenciaTipoRenglónPropósito"
                        value={propósito.frecuenciaTipoRenglónPropósito}
                        onChange={(e) => handleRenglónPropósitoChange(e, index)}
                        required
                      />
                      <label>MEDIOS DE VERIFICACIÓN:</label>
                      <textarea
                        name="mediosVerificaciónRenglónPropósito"
                        value={propósito.mediosVerificaciónRenglónPropósito}
                        onChange={(e) => handleRenglónPropósitoChange(e, index)}
                        required
                      />
                      <label>SUPUESTOS:</label>
                      <textarea
                        name="supuestosRenglónPropósito"
                        value={propósito.supuestosRenglónPropósito}
                        onChange={(e) => handleRenglónPropósitoChange(e, index)}
                        required
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
              <div className="formulario-mir-buttons">
                <div className="formulario-mir-add-activity">
                  <button type="button" onClick={agregarRenglónPropósito}>
                    Agregar renglón propósito
                  </button>
                </div>
                <div className="formulario-mir-delete-activity">
                  <button type="button" onClick={eliminarRenglónPropósito}>
                    Borrar renglón propósito
                  </button>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>RENGLÓN COMPONENTE</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="columna">
              {formData.componente.map((componente, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Componente {index + 1}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="columna">
                      <label>OBJETIVO O RESUMEN NARRATIVO:</label>
                      <textarea
                        name="objetivoRenglónComponente"
                        value={componente.objetivoRenglónComponente}
                        onChange={(e) =>
                          handleRenglónComponenteChange(e, index)
                        }
                        required
                      />
                      <label>NOMBRE:</label>
                      <textarea
                        name="nombreRenglónComponente"
                        value={componente.nombreRenglónComponente}
                        onChange={(e) =>
                          handleRenglónComponenteChange(e, index)
                        }
                        required
                      />
                      <label>FÓRMULA:</label>
                      <textarea
                        name="fórmulaRenglónComponente"
                        value={componente.fórmulaRenglónComponente}
                        onChange={(e) =>
                          handleRenglónComponenteChange(e, index)
                        }
                        required
                      />
                      <label>FRECUENCIA Y TIPO:</label>
                      <textarea
                        name="frecuenciaTipoRenglónComponente"
                        value={componente.frecuenciaTipoRenglónComponente}
                        onChange={(e) =>
                          handleRenglónComponenteChange(e, index)
                        }
                        required
                      />
                      <label>MEDIOS DE VERIFICACIÓN:</label>
                      <textarea
                        name="mediosVerificaciónRenglónComponente"
                        value={componente.mediosVerificaciónRenglónComponente}
                        onChange={(e) =>
                          handleRenglónComponenteChange(e, index)
                        }
                        required
                      />
                      <label>SUPUESTOS:</label>
                      <textarea
                        name="supuestosRenglónComponente"
                        value={componente.supuestosRenglónComponente}
                        onChange={(e) =>
                          handleRenglónComponenteChange(e, index)
                        }
                        required
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
              <div className="formulario-mir-buttons">
                <div className="formulario-mir-add-activity">
                  <button type="button" onClick={agregarRenglónComponente}>
                    Agregar renglón componente
                  </button>
                </div>
                <div className="formulario-mir-delete-activity">
                  <button type="button" onClick={eliminarRenglónComponente}>
                    Borrar renglón componente
                  </button>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              MATRIZ DE INDICADORES DE RESULTADOS (ACTIVIDADES)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="columna">
              {formData.actividades.map((actividad, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Actividad No. {index + 1}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="columna">
                      <label>OBJETIVO:</label>
                      <textarea
                        name="objetivoActividad"
                        value={actividad.objetivoActividad}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                      <label>NOMBRE:</label>
                      <textarea
                        name="nombreActividad"
                        value={actividad.nombreActividad}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                      <label>FÓRMULA:</label>
                      <textarea
                        name="fórmulaActividad"
                        value={actividad.fórmulaActividad}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                      <label>FRECUENCIA Y TIPO:</label>
                      <textarea
                        name="frecuenciaTipoActividad"
                        value={actividad.frecuenciaTipoActividad}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                      <label>MEDIOS DE VERIFICACIÓN:</label>
                      <textarea
                        name="mediosVerificaciónActividad"
                        value={actividad.mediosVerificaciónActividad}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                      <label>SUPUESTOS:</label>
                      <textarea
                        name="supuestosActividad"
                        value={actividad.supuestosActividad}
                        onChange={(e) => handleActividadChange(e, index)}
                        required
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
              <div className="formulario-mir-buttons">
                <div className="formulario-mir-add-activity">
                  <button type="button" onClick={agregarActividad}>
                    Agregar una actividad
                  </button>
                </div>
                <div className="formulario-mir-delete-activity">
                  <button type="button" onClick={eliminarActividad}>
                    Borrar una actividad
                  </button>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>RESPONSABLE Y TITULAR</Typography>
          </AccordionSummary>
          <div className="columna">
            <label>RESPONSABLE DE LA ELABORACIÓN:</label>
            <textarea
              name="responsableElaboracion"
              value={formData.responsableElaboracion}
              onChange={handleChange}
              required
            />
            <label>TITULAR DE LA DEPENDENCIA:</label>
            <textarea
              name="titularDependencia"
              value={formData.titularDependencia}
              onChange={handleChange}
              required
            />
            <label>USUARIO QUE CREA LA MIR:</label>
            <textarea
              name="nombreUsuario"
              value={formData.userName}
              onChange={handleChange}
              disabled
            />
          </div>
        </Accordion>

        <div className="formulario-mir-submit-container">
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              handleConfirmDialogOpen();
            }}
          >
            GUARDAR MATRIZ DE INDICADORES DE RESULTADOS
          </button>
        </div>
      </form>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogTitle>GUARDAR MATRIZ DE INDICADORES DE RESULTADOS</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "justify" }}>
            <Typography
              component="span"
              variant="body1"
              style={{ fontWeight: "bold" }}
            >
              ¿Está seguro de que desea guardar la MATRIZ DE INDICADORES DE
              RESULTADOS?
            </Typography>
            <br />
            Verifique bien los datos agregados a su MIR (Vinculaciones,
            clasificación, actividades, responsable y titular), ya que una vez
            guardado no podrá modificarlos después.
            <br />
          </DialogContentText>
          <TextField
            label="Guardar como... Ejemplo: (MATRIZ DE INDICADORES DE RESULTADOS 2024)"
            value={nombreMIR}
            onChange={handleNombreMIRChange}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      
    </div>
  );
}

export default FormularioMIR;
